<template>
<div v-if="loading">
    <Loading :active.sync="loading" color="#042446"></Loading>
</div>
<div class="scope"  v-else>
    <b-container>
        <h3>ลืมรหัสผ่าน</h3>
        <div>
            <b-alert :variant="showStatus" v-if="showStatus!=false" show><b-spinner v-if="loadingLogin" small label="Small Spinner" class="mr-2"></b-spinner>{{statusText}}</b-alert>
            <ValidationObserver ref="observer" v-slot="{ invalid }" tag="form">
                <div class="input">
                    <ValidationProvider :rules="{ regex: /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[.#?!@$%^&*-]).{8,}$/ , required: true}" v-slot="{ errors }" vid="confirmation">
                        <label for="password">รหัสผ่าน</label>
                        <b-form-input type="password" id="password" v-model="password" placeholder="รหัสผ่าน"></b-form-input>
                        <span class="errors" v-if="errors.length > 0 && errors[0] != 'กรุณากรอกข้อมูล'">ขั้นต่ำ 8 ตัวอักษร และต้องประกอบด้วยตัวอักษรพิมพ์ใหญ่, พิมพ์เล็ก, ตัวเลขและอักขระพิเศษอย่างน้อยชนิดละ 1 ตัว</span>
                        <span class="errors" v-else>{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="input">
                    <ValidationProvider rules="required|confirmed:confirmation" v-slot="{ errors }">
                        <label for="cfpassword">ยืนยันรหัสผ่าน</label>
                        <b-form-input type="password" id="cfpassword" v-model="cfpassword" placeholder="ยืนยันรหัสผ่าน"></b-form-input>
                        <span class="errors">{{ errors[0] }}</span>
                    </ValidationProvider>
                </div>
                <div class="searching">
                    <b-button class="login" @click="updatepass(password)"  :disabled="invalid">
                        <b-spinner v-if="loadingSet" small label="Small Spinner" class="mr-2"></b-spinner>บันทึกรหัสผ่านใหม่
                    </b-button>
                </div>
            </ValidationObserver>
        </div>
    </b-container>
</div>
</template>


<script>
import axios from 'axios'
import router from './../../router/index'
import {
    mapGetters,
    mapActions
} from 'vuex'
export default {
    watch: {
        mode() {
            this.checkMode()
        }
    },
    data() {
        return {
            setPass: false,
            loadingSet: false,
            showStatus: false,
            statusText: ''
        }
    },
    computed: {
        ...mapGetters({
            loginStatus: 'GET_LOGIN_STATUS',
            loading: 'GET_LOADING',
            mode: 'GET_MODE',
            loadingLogin: false
        })
    },
    async mounted() {
        this.SET_LOADING_STATUS(true)
        this.firstcheck()
        this.SET_LOADING_STATUS(false)
        this.setPass = true
    },
    methods: {
        ...mapActions(['SET_LOADING_STATUS', 'SET_LOGIN_STATUS', 'SET_USER_INFO']),
        firstcheck(){
            if(this.loginStatus!=false){
                router.push('/')
            }
        },
        async updatepass(password) {
            this.loadingForget = true
            const formData = new FormData();
            formData.append('password', password);
            formData.append('token', this.$route.params.token);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/setpass", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            this.statusText = 'บันทึกรหัสผ่านสำเร็จ'
                            this.showStatus = 'success'
                            this.loadingLogin = true
                            this.login(res.data.username, password)
                        } else if (res.data.status == 404) {
                            this.statusText = 'เกิดข้อผิดพลาด กรุณาติดต่อเจ้าหน้าที่'
                            this.showStatus = 'danger'
                        }
                        this.loadingSet = false
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        },
        async login(username, password) {
            this.loadingLogin = true
            //console.log(username + " : " + password)
            const formData = new FormData();
            formData.append('username', username);
            formData.append('password', password);
            try {
                await axios
                    .post("https://api2.novelrealm.com/module/login", formData)
                    .then(res => {
                        //console.log(res)
                        if (res.data.status == 200) {
                            localStorage.setItem('AutoLogin', false);
                            this.SET_USER_INFO({
                                auth_token: res.data.auth_reference,
                                auth_memberid: res.data.auth_memberid,
                                displayname: res.data.member_displayname,
                                member_gold: res.data.member_gold,
                                member_profile_url: res.data.member_profile_url,
                                member_is_admin: res.data.member_is_admin,
                                member_is_publisher: res.data.member_is_publisher
                            })
                            this.SET_LOGIN_STATUS(true);
                            router.push('/')
                        }
                        this.loadingLogin = false
                    })
                    .catch(err => {
                        console.error("login_error : " + err);
                    });
            } catch (err) {
                console.error("login_error : " + err);
            }
        }
    }
}
</script>

<style scoped>
.scope {
    background-color: #ffffff;
    padding: 2% 10% 4% 10%;
    margin: 3% 10% 3% 10%;
}

input {
    border-color: #A8A8A8;
    font-size: 14px;
    border-radius: 6px;

}

.errors {
    font-size: 14px;
    color: #EE806B;
    margin-bottom: 15px;
    display:block;
}

@media screen and (min-width: 1441px) {
    .scope {
        margin: 3% 20% 3% 20%;
    }
}

.darkmode {
    background-color: #35363A;
}

.darkmodeText{
    color: #ffffff;
}
</style>